.Room {
  background-color: #000;
  display: flex;
  flex-flow: column-reverse;
  padding: 1rem;
  height: 100%;
}

.twitch-container {
  display: flex;
  width: 100%;
  height: 75%;
}

.peers-container {
  display: flex;
  justify-content: space-around;
  height: 25%;
  margin-bottom: 1rem;
}

.peer-video {
  max-width: 250px;
}

.twitch {
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
    border: 5px solid #6441a4;
    border-radius: 30px;
  }
}

#my-video {
  border-color: #fc5185;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 5px solid;
  border-color: #3fc1c9;
  border-radius: 30px;
  transform: rotateY(180deg);
}

@media only screen and (max-width: 550px) {
  .App {
    padding: 0;
    background-color: #000;
  }

  .twitch-container {
    height: 100vh;
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  .twitch {
    iframe {
      border: none;
      border-radius: 0;
    }
  }

  .peers-container {
    width: 100vw;
    height: 72px;
    position: absolute;
    top: 0;
    left: 1px;
    z-index: 1;
    margin: 0;
  }

  video {
    border-width: 1px;
    border-radius: 0;
    width: 70px;
    height: 70px;
  }
}

@media only screen and (orientation: landscape) and (max-width: 900px) {
  .App {
    padding: 0;
    background-color: #000;
  }

  .twitch-container {
    height: 100vh;
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  .twitch {
    iframe {
      border: none;
      border-radius: 0;
    }
  }

  .peers-container {
    height: 100vh;
    flex-flow: column;
    position: absolute;
    top: 0;
    left: 1px;
    z-index: 1;
    margin: 0;
  }

  video {
    border-width: 1px;
    border-radius: 0;
    width: 70px;
    height: 70px;
  }
}
